import { useSelector } from "react-redux";
import {
  fetchLicenseKey,
  fetchUserInfo,
  getLicenseKey,
  getUserInfo,
} from "../slices/authSlice";
import Title from "../components/TitleChanger";
import { IconLicense } from "@tabler/icons-react";
import { useEffect } from "react";
import { useAppDispatch } from "../store";

export default function LicenseView() {
  const dispatch = useAppDispatch();
  const licenseKey = useSelector(getLicenseKey);
  const user = useSelector(getUserInfo);

  useEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(fetchLicenseKey());
  }, [dispatch]);

  console.log(licenseKey);
  return (
    <div className="page">
      <Title pageTitle={"Downloads | "} />
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1 className="display-6" style={{ fontWeight: "700" }}>
              <IconLicense style={{ paddingBottom: "5px" }} size={"36"} />
              License
            </h1>
            <hr />
          </div>
        </div>
        <div className="col-md-10 offset-md-1 " style={{ height: "400px" }}>
          {licenseKey === "" && (
            <>
              <p>
                Please purchase license and use your accout email{" "}
                <b>{user.email}</b> during checkout.
              </p>
              <p>
                <div className="alert alert-primary" role="alert">
                  MLJAR Studio is in an early development phase. We offer 50%
                  disount for early users. Please use <b>studio-early-user</b>{" "}
                  code during checkout. You will show us a great support by
                  purchasing license. Thank you!
                </div>
              </p>
              <a
                href={`https://mljar.com/pricing/?customerData=${user.email}`}
                target="_blank"
                className="btn btn-outline-dark "
                rel="noreferrer"
              >
                <IconLicense style={{ paddingBottom: "5px" }} /> Buy license
              </a>
            </>
          )}

          {licenseKey !== "" && (
            <>
              <p>
                You can find here your license key for MLJAR Studio. If you have
                any problems with license activation or need help please contact
                us at <b>contact@mljar.com</b>.
              </p>
              <label>
                <b>License key</b>
              </label>
              <input
                type="text"
                className="form-control"
                value={licenseKey}
              ></input>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
