import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { RootState, TypedDispatch } from "../store";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { setAxiosAuthToken } from "./utils";

let initToken = null;
if (localStorage.getItem("token")) {
  initToken = localStorage.getItem("token");
  setAxiosAuthToken(initToken);
}

type ProfileType = { plan: string };
export type UserType = {
  pk: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  profile: ProfileType;
};
export type ReleaseType = {
  id: number;
  name: string;
  operating_system: string;
  version: string;
  created_at: Date;
  file_size: string;
  checksum: string;
  latest: boolean;
};

export type LicenseType = {
  license: string;
  version: string;
};

const initialState = {
  token: initToken as string | null, // add token variable
  //user information
  user: {
    pk: 0,
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    profile: {
      plan: "unknown",
    },
  } as UserType,
  releases: [] as ReleaseType[],
  usernameRegisterError: "", // initial value is set to empty string
  emailRegisterError: "",
  password1RegisterError: "",
  verifyEmailStatus: "unknown",
  blockUI: false,
  downloadLinks: {} as Record<number, string>,
  licenseKey: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
      setAxiosAuthToken(state.token);
      if (state.token) {
        localStorage.setItem("token", state.token);
      } else {
        localStorage.removeItem("token");
      }
    },
    setUserInfo(state, action: PayloadAction<UserType>) {
      state.user = action.payload;
    },
    setUsernameRegisterError(state, action: PayloadAction<string>) {
      state.usernameRegisterError = action.payload;
    },
    setEmailRegisterError(state, action: PayloadAction<string>) {
      state.emailRegisterError = action.payload;
    },
    setPassword1RegisterError(state, action: PayloadAction<string>) {
      state.password1RegisterError = action.payload;
    },
    setVerifyEmailStatus(state, action: PayloadAction<string>) {
      state.verifyEmailStatus = action.payload;
    },
    setBlockUI(state, action: PayloadAction<boolean>) {
      state.blockUI = action.payload;
    },
    setDownloadLink(state, action: PayloadAction<{ id: number, link: string }>) {
      state.downloadLinks[action.payload.id] = action.payload.link;
    },
    setReleases(state, action: PayloadAction<ReleaseType[]>) {
      state.releases = action.payload;
    },
    setLicenseKey(state, action: PayloadAction<string>) {
      state.licenseKey = action.payload;
    },
  },
});

export default authSlice.reducer;

export const {
  setToken,
  setUserInfo,
  setUsernameRegisterError,
  setEmailRegisterError,
  setPassword1RegisterError,
  setVerifyEmailStatus,
  setBlockUI,
  setDownloadLink,
  setReleases,
  setLicenseKey,
} = authSlice.actions;

export const getUserInfo = (state: RootState) => state.auth.user;
export const getUsernameRegisterError = (state: RootState) =>
  state.auth.usernameRegisterError;
export const getEmailRegisterError = (state: RootState) =>
  state.auth.emailRegisterError;
export const getPassword1RegisterError = (state: RootState) =>
  state.auth.password1RegisterError;
export const getVerifyEmailStatus = (state: RootState) =>
  state.auth.verifyEmailStatus;
export const getBlockUI = (state: RootState) => state.auth.blockUI;
export const getToken = (state: RootState) => state.auth.token;
export const getDownloadLinks = (state: RootState) => state.auth.downloadLinks;
export const getReleases = (state: RootState) => state.auth.releases;
export const isAuthenticated = (state: RootState) => (state.auth.token !== null && state.auth.token !== "");
export const getLicenseKey = (state: RootState) => state.auth.licenseKey;

export const register =
  (
    username: string,
    email: string,
    password1: string,
    password2: string,
    navigate: NavigateFunction
  ) =>
    async (dispatch: TypedDispatch) => {
      try {
        // start blocking
        dispatch(setBlockUI(true));
        // clear register errors
        dispatch(setUsernameRegisterError(""));
        dispatch(setEmailRegisterError(""));
        dispatch(setPassword1RegisterError(""));

        const url = "/api/auth/register/";
        await axios.post(url, { username, email, password1, password2 });
        dispatch(setBlockUI(false));
        navigate("/verify-email-sent");
      } catch (error) {
        // stop blocking
        dispatch(setBlockUI(false));

        const err = error as AxiosError;

        type RegisterErrorType = {
          non_field_errors?: string[];
          username?: string[];
          email?: string[];
          password?: string[];
        };

        const data = err.response?.data as RegisterErrorType;

        if (data.username) {
          dispatch(setUsernameRegisterError(data.username.join(" ")));
        }
        if (data.email) {
          dispatch(setEmailRegisterError(data.email.join(" ")));
        }
        if (data.password) {
          dispatch(setPassword1RegisterError(data.password.join(" ")));
        }
        if (data.non_field_errors) {
          toast.error(`Problem during authentication. ${data.non_field_errors}`);
        }
      }
    };

export const verifyEmail = (key: string) => async (dispatch: TypedDispatch) => {
  try {
    // set status to started
    dispatch(setVerifyEmailStatus("started"));

    // send POST request
    const url = "/api/auth/register/verify-email/";
    await axios.post(url, { key });

    // set verify email status to ok
    dispatch(setVerifyEmailStatus("ok"));
  } catch (error) {
    // set status to error
    dispatch(setVerifyEmailStatus("error"));
  }
};

export const login =
  (
    email: string,
    password: string,
    redirectPath: string,
    navigate: NavigateFunction
  ) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = "/api/auth/login/";
        const { data } = await axios.post(url, { email, password });

        dispatch(setToken(data.key));
        // redirect ...
        navigate(redirectPath);
      } catch (error) {
        toast.error("Problem during login. Please try again.");
      }
    };

export const fetchUserInfo = () => async (dispatch: TypedDispatch) => {
  try {
    // do a GET request
    const url = "/api/auth/user/";
    const { data } = await axios.get(url);

    // set the user info in the store
    dispatch(setUserInfo(data));
  } catch (error) {
    toast.error("Error occurred when fetching user information");
  }
};

export const resetPassword =
  (email: string, navigate: NavigateFunction) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = "/api/auth/password/reset/";
        const data = { email };
        await axios.post(url, data);

        navigate("/reset-password-email-sent");
      } catch (error) {
        toast.error(
          "Problems with password reset. Please try again or contact administrator."
        );
      }
    };

export const setNewPassword =
  (
    uid: string,
    token: string,
    password1: string,
    password2: string,
    navigate: NavigateFunction
  ) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = "/api/auth/password/reset/confirm/";
        const data = {
          uid,
          token,
          new_password1: password1,
          new_password2: password2,
        };
        await axios.post(url, data);

        toast.success("New password set successfully");

        navigate("/login");
      } catch (error) {
        const err = error as AxiosError;

        type RegisterErrorType = {
          non_field_errors?: string[];
          uid?: string[];
          token?: string[];
          new_password1?: string[];
          new_password2?: string[];
        };

        const data = err.response?.data as RegisterErrorType;

        if (data.non_field_errors) {
          toast.error(data.non_field_errors.join(" "));
        }
        if (data.uid) {
          toast.error("UID " + data.uid.join(" "));
        }
        if (data.token) {
          toast.error("Token " + data.token.join(" "));
        }
        if (data.new_password1) {
          toast.error("New Password 1 " + data.new_password1.join(" "));
        }
        if (data.new_password2) {
          toast.error("New Password 2 " + data.new_password2.join(" "));
        }
      }
    };

export const logout =
  (navigate: NavigateFunction) => async (dispatch: TypedDispatch) => {
    try {
      dispatch(setToken(""));
      dispatch(setUserInfo({} as UserType));
      navigate("/");

      const url = "/api/auth/logout/";
      await axios.post(url);
    } catch (error) { }
  };

export const changePassword =
  (oldPassword: string, password1: string, password2: string) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = "/api/auth/password/change/";
        const data = {
          new_password1: password1,
          new_password2: password2,
          old_password: oldPassword,
        };
        await axios.post(url, data);

        toast.success("Password changed successfully");
      } catch (error) {
        const err = error as AxiosError;

        type RegisterErrorType = {
          non_field_errors?: string[];
          new_password1?: string[];
          new_password2?: string[];
          old_password?: string[];
        };

        const data = err.response?.data as RegisterErrorType;

        if (data.non_field_errors) {
          toast.error(data.non_field_errors.join(" "));
        }
        if (data.new_password1) {
          toast.error(data.new_password1.join(" "));
        }
        if (data.new_password2) {
          toast.error(data.new_password2.join(" "));
        }
        if (data.old_password) {
          toast.error(data.old_password.join(" "));
        }
      }
    };

export const deleteAccount =
  (navigate: NavigateFunction) => async (dispatch: TypedDispatch) => {
    try {
      const url = "/api/auth/delete-account/";
      await axios.delete(url);

      dispatch(setToken(""));
      dispatch(setUserInfo({} as UserType));
      navigate("/");
    } catch (error) {
      const err = error as AxiosError;

      type RegisterErrorType = {
        msg?: string;
      };

      const data = err.response?.data as RegisterErrorType;

      if (data.msg) {
        toast.error(data.msg);
      } else {
        toast.error("Problem during account removal. Please try again.");
      }
    }
  };

export const downloadStudio =

  (navigate: NavigateFunction, releaseId: number) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = "/api/download/";
        const { data } = await axios.post(url, { releaseId });
        dispatch(setDownloadLink({ id: data.id, link: data.link }));
        setTimeout(() => {
          dispatch(setDownloadLink({ id: data.id, link: "" }));
        }, 2 * 60 * 60 * 1000);
      } catch (error) {
        const err = error as AxiosError;
        type RegisterErrorType = {
          msg?: string;
        };
        const data = err.response?.data as RegisterErrorType;
        if (data.msg) {
          toast.error(data.msg);
        } else {
          toast.error(
            "Problem during comunication with s3 sever. Please try again."
          );
        }
      }
    };

export const fetchReleases = () => async (dispatch: TypedDispatch) => {
  try {
    const url = "/api/releases/";
    const { data } = await axios.get(url);
    dispatch(setReleases(data));
  } catch (error) {
    const err = error as AxiosError;
    type RegisterErrorType = {
      msg?: string;
    };
    const data = err.response?.data as RegisterErrorType;
    if (data.msg) {
      toast.error(data.msg);
    } else {
      toast.error("Could not get DB intel.");
    }
  }
};


export const fetchLicenseKey = () => async (dispatch: TypedDispatch) => {
  try {
    console.log('fetch key')
    const url = "/api/get-license/";
    const { data } = await axios.get(url);
    console.log(data);
    if (data.license !== undefined) {
      dispatch(setLicenseKey(data.license));
    }
  } catch (error) {
    toast.error("Error occurred when fetching license key");
  }
};