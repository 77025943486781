import { useEffect } from "react";
import { useSelector } from "react-redux";
import ChangePassword from "../components/ChangePassword";
import DeleteAccount from "../components/DeleteAccount";

import { fetchUserInfo, getUserInfo } from "../slices/authSlice";
import { useAppDispatch } from "../store";
import Title from "../components/TitleChanger";
import { IconUser } from "@tabler/icons-react";

export default function ProfileView() {
  const dispatch = useAppDispatch();
  const user = useSelector(getUserInfo);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  return (
    <div>
    <Title pageTitle={ 'Profile | ' } />
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
          <h1 className="display-6" style={{ fontWeight: "700" }}>
              <IconUser
                style={{ paddingBottom: "5px" }}
                size={"36"}
              />
              Profile
            </h1>
            <hr />
            <p><b>Username:</b> {user.username}</p>
            <p><b>Email:</b> {user.email}</p>
            <hr />
            <ChangePassword />
            <hr />
            <DeleteAccount user={user} />
          </div>
        </div>
      </div>
    </div>
  );
}
