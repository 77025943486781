import Title from "../components/TitleChanger";

export default function TermsOfServiceView() {
  return (
    <div>
    <Title pageTitle={ 'Terms of Service | ' } />
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1>Terms Of Service</h1>

            <p>
              Welcome to <b>MLJAR sp. z o. o.</b> ("Company", "we", "our",
              "us")!
              <br />
              Terms of Service (<b>"Terms"</b>) govern your use of our website
              located at mljar.com.com (<b>"Service"</b>,{" "}
              <b>"Monitor Uptime"</b>) operated by <b>MLJAR sp. z o. o.</b>
              <br />
              Our Privacy Policy governs your use of our Service and explains
              how we collect, safeguard, and disclose information that results
              from your use of our web pages.
              <br />
              Your Agreement with us includes these Terms and our Privacy Policy
              (<b>"Agreements"</b>). You confirm that you have read and
              understood the Agreement and consent to its conclusion.
              <br />
              If you do not agree with (or cannot comply with) Agreements, then
              you may not use the Service. Please let us know by e-mailing at
              contact@mljar.com.com so we can try to find a solution. Terms
              apply to all visitors, users, and others who wish to access or use
              Service.
              <br />
              On our platform, we offer a server monitoring service.
            </p>

            <h2>Communications</h2>
            <p>
              By using our Service, you agree to be contacted by us in order to
              enable you to use the service, notify you of significant changes
              to the website, and other important information regarding Monitor
              Uptime (such as temporary unavailability of the service, changes
              in the Privacy Policy or Terms of Service). You may also agree to
              subscribe to newsletters, marketing or promotional materials, and
              other information regarding services operated by MLJAR sp. z o. o.
              You may unsubscribe from receiving any or all of these
              communications from us by using the unsubscribe link or sending an
              e-mail to contact@mljar.com.com.
            </p>

            <h2>Accounts</h2>
            <p>
              To perform most activities on our platform account is required.
              <br />
              You are responsible for maintaining your account and password's
              confidentiality, including but not limited to the restriction of
              access to your computer and/or account. Service is intended only
              for access and use by individuals at least 18 years old. When you
              create an account in Monitor Uptime, you guarantee that the
              information you provide is accurate, complete, and current at all
              times. Inaccurate, incomplete, or obsolete information may result
              in the immediate termination of your account on Service. You must
              notify us immediately upon becoming aware of any breach of
              security or unauthorized use of your account. You agree to accept
              responsibility for all activities that occur under your account,
              whether it is with our Service or a third-party service.
              <br />
              You can delete your account at any time by clicking on your
              profile "delete my profile". We will provide you an e-mail
              confirming that your account was deleted. By deleting your
              account, you will lose access to all tutorials even if your
              Subscription is still valid. Then the proportionally amount of not
              used Subscription won't be returning.
              <br />
              You may not use as a username the name of another person or
              entity, or that is not lawfully available for use, a name or
              trademark that is subject to any rights of another person or
              entity other than you, without appropriate authorization. You may
              not use as a username any name that is offensive, vulgar, or
              obscene. In such cases, we reserve the right to refuse service,
              cancel accounts, or cancel orders in justified cases.
              <br />
              Technical requirements necessary to use the service:
              <ul>
                <li>Internet access,</li>
                <li>a web browser that supports cookies,</li>
                <li>an active and properly configured e-mail account.</li>
              </ul>
            </p>

            <p>
              The company is not responsible for accidents resulting from the
              user's failure to comply with the above technical requirements
              necessary for cooperation with the ICT system that he uses.
            </p>

            <h2>Payments</h2>
            <p>
              If you want to buy a subscription, you will be asked to provide
              information related to the purchase, such as credit or debit card
              number, expiration date, billing address, country of residence.
              The prices listed on the website are expressed in US dollars and
              are net prices. Dependent on your country of residence, the proper
              tax will be added.
              <br />
              You agree to pay the fees for subscription that you purchase, and
              you authorize us to charge your debit or credit card or process
              other means of payment for those fees.
              <br />
              You represent and warrant that you have the legal right to use any
              card or other payment method in connection with any purchase; and
              that the information you supply is true, correct, and complete.
              <br />
              We may employ the use of third-party services to facilitate and
              the completion of payment (Paddle). By submitting your
              information, you grant us the right to provide the information to
              these third parties subject to our Privacy Policy. We work with
              payment service providers to offer you the most convenient payment
              methods in your country and to keep your payment information
              secure.
              <br />
              We reserve the right to refuse or cancel your order at any time
              for reasons including but not error in your order or other
              reasons, and if fraud or an unauthorized or illegal transaction is
              suspected.
              <br />
            </p>

            <h2>Subscriptions</h2>
            <p>
              <br />
              Monitor Uptime can be billed on a subscription per month or year
              ("Subscription"). You will be billed in advance on a recurring and
              periodic basis ("Billing Cycle"). Billing cycles will be set
              depending on the type of subscription plan you select when
              purchasing a Subscription.
              <br />
              At the end of each Billing Cycle, your Subscription will
              automatically renew under the same conditions unless you cancel it
              or MLJAR sp. z o. o. cancels it. You may cancel your Subscription
              renewal either through your online account management page or by
              contacting on contact@mljar.com.com.
              <br />
              Should automatic billing fail to occur for any reason, MLJAR sp. z
              o. o. reserves the right to terminate your access to the Service
              with immediate effect.
            </p>

            <h2>Fee Changes</h2>
            <p>
              MLJAR sp. z o. o., at any time, may modify Subscription fees for
              the Subscriptions. Any Subscription fee change will become
              effective at the end of the then-current billing cycle. MLJAR sp.
              z o. o. will provide you with reasonable prior notice of any
              change in Subscription fees to allow you to terminate your
              Subscription before such change becomes effective. Your continued
              use of Service after the Subscription fee change comes into effect
              constitutes your agreement to pay the modified Subscription fee
              amount.
            </p>

            <h2>Content</h2>
            <p>
              The Content found on or through our Service is the property of
              MLJAR sp. z o. o. You may not distribute, modify, transmit, reuse,
              download, repost, copy, or use said Content, whether in whole or
              in part, for commercial purposes, without advance written
              permission from us.
              <br />
              <br />
              Our Service allows you to post, link, share, and otherwise make
              available certain information, text, graphics, videos, or other
              material.
              <br />
              You retain all of your rights to any Content you submit, post, or
              display on or through Service, and you are responsible for
              protecting those rights. We take no responsibility and assume no
              liability for Content you or any third party posts on our Service.
            </p>

            <h2>Prohibited Uses</h2>
            <p>
              You may use Service only for lawful purposes and under the Terms
              and Conditions. You agree not to use Service:
              <ul>
                <li>
                  to attempt or impersonate to Company, a Company employee,
                  another user, or any other person or entity;
                </li>
                <li>
                  in any manner that could disable, overburden, damage, or
                  impair Service or interfere with any other party's ( e.g.,
                  user, host, network) use of Service, including their ability
                  to engage in real-time activities through Service;
                </li>
                <li>
                  to access, tamper with, or use non-public areas of the
                  platform (including content storage), computer systems, or
                  service providers' technical delivery systems;
                </li>
                <li>
                  to disable, interfere with, or try to avoid any of the
                  platforms' features related to security or probe, scan, or
                  test any of our systems' vulnerability;
                </li>
                <li>
                  to introduce any viruses, trojan horses, worms, logic bombs,
                  or other malicious or technologically harmful material;
                </li>
                <li>
                  to copy, modify, create a plagiarized work of, reverse
                  engineer, or differently attempt to discover any source code
                  of or Content on the Monitor Uptime;
                </li>
                <li>
                  access or search or try to access or search our platform by
                  any means (automated or otherwise) other than through our
                  currently available search functionalities provided by our
                  website, mobile apps, or API (and only according to those API
                  terms and conditions).
                </li>
              </ul>
            </p>

            <h2>Feedback and errors</h2>
            <p>
              You may provide us either directly at contact@mljar.com.com
              with information and Feedback concerning errors, suggestions for
              improvements, ideas, problems, complaints, and other matters
              related to our Service ("Feedback").
              <br />
              You acknowledge and agree that you shall not acquire or assert any
              intellectual property right to the Feedback; Company may have
              developed ideas similar to the Feedback; Feedback does not contain
              confidential information or proprietary information from you or
              any third party, and Company is not under any obligation of
              confidentiality concerning the Feedback. In the event the transfer
              of the ownership to the Feedback is not possible due to applicable
              mandatory laws, you grant Company an exclusive, transferable,
              irrevocable, free-of-charge, sub-licensable, unlimited, and
              perpetual right to use Feedback in any manner and for any purpose
              (including copy, modify, publish, distribute and commercialize).
            </p>

            <h2>Disclaimer of Warranty</h2>
            <p>
              The services contained in the Monitor Uptime are provided "as is"
              and "as available". The company makes no representations or
              warranties, express or implied, about the operation of its
              services or the information, Content, or materials therein. You
              expressly agree that your use of these services, their content,
              and any services or items obtained from us at your own risk.
              <br />
              Although we make every effort to meet your expectations, we cannot
              ensure that the service meets your needs. We make no guarantees
              that you will receive these results due to your use of the
              Service.
              <br />A user who is a consumer may apply to the Provincial
              Inspectorate of the Trade Inspection in Bialystok to initiate
              proceedings regarding the out-of-court settlement of consumer
              disputes regarding the concluded Agreement
              (inspekcja.handlowa@bialystok.wiih.gov.pl). Out-of-court
              processing of complaints and redress is also possible through the
              European Commission's platform for online dispute resolution
              between consumers (only those from the EU) and ODR entrepreneurs.
              It is available at http://ec.europa.eu/consumers/odr/. The
              consumer may apply to this entity to initiate proceedings for the
              out-of-court resolution of consumer disputes.
            </p>

            <h2>Termination</h2>
            <p>
              If you want to close your account, you can click the "delete
              account" button on your panel after logging in. Then all your data
              will be deleted irretrievably.
              <br />
              If the account has not been active for at least two years - it
              will be automatically deleted. If you want to use the website
              again, you will need to register once more.
              <br />
              All of the provisions, which by their nature should survive
              termination, will survive termination, including, but not limited
              to, the provisions relating to ownership, disclaimers of
              warranties, damages, and limitations of liability.
            </p>

            <h2>Governing Law</h2>
            <p>
              These Terms shall be governed and construed according to Poland's
              laws, which governing law applies to Agreement without regard to
              its conflict of law provisions.
              <br />
              Our failure to enforce any right or provision of these Terms will
              not be considered a waiver of those rights. If any provision of
              these Terms is held to be invalid or unenforceable by a court,
              these Terms' remaining provisions will remain in effect. These
              Terms with Privacy Policy constitute the entire Agreement between
              us regarding our Service.
            </p>

            <h2>Changes in Monitor Uptime</h2>
            <p>
              We reserve the right to withdraw or amend our Service, and any
              service or material we provide in Service, in our sole discretion
              without notice. We will not be liable if for any reason all or any
              part of Service is unavailable at any time or for any period. From
              time to time, we may restrict access to some service features, or
              the entire Service, to users, including registered users.
            </p>

            <h2>Amendments to Terms</h2>
            <p>
              We may amend Terms at any time by posting them on this site. It is
              your responsibility to review these Terms periodically.
              <br />
              Your continued use of the platform following the posting of
              revised Terms means that you accept and agree to the changes.
              <br />
              By continuing to access or use our Service after revisions become
              effective, you agree to be bound by the revised terms. If you do
              not agree to the new terms, you are no longer authorized to use
              Service.
            </p>

            <h2>Analytics</h2>
            <p>
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </p>

            <h2>Intellectual Property</h2>
            <p>
              We own the Monitor Uptime platform and Services, including the
              website, logos, API, code and Content, apps, and services.
              <br />
              SaaSitive is protected by copyright, trademark, and other laws of
              and foreign countries. Our trademarks may not be used in
              connection with any product or service without the prior written
              consent of MLJAR sp. z o. o.
              <br />
              By using Service, you acknowledge that you have read these Terms
              of Service and agree to be bound by them.
            </p>

            <h2>Contact Us</h2>
            <p>
              Please send your Feedback, comments, requests for technical
              support by e-mail: contact@mljar.com.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
