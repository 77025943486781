import { Link, useNavigate } from "react-router-dom";
import { logout } from "../slices/authSlice";
import { useAppDispatch } from "../store";
import {
  IconDownload,
  IconLayoutDashboard,
  IconLicense,
  IconLogout,
  IconUser,
} from "@tabler/icons-react";

export default function AppNavBar() {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="container">
      <header className="d-flex flex-wrap justify-content-center pt-3 mb-4 px-5 mx-5">
        <Link
          to="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <img
            alt="mljar logo"
            src={process.env.PUBLIC_URL + "/logo_blue.png"}
            style={{
              height: "50px",
              padding: "0px",
              margin: "0px 10px 0px 0px",
            }}
          />
          <span className="fs-4">Licenses</span>
        </Link>

        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link to="/app" className="nav-link">
              <IconLayoutDashboard style={{ paddingBottom: "5px" }} />
              Dashboard
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/app/downloads" className="nav-link">
              <IconDownload style={{ paddingBottom: "5px" }} />
              Downloads
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/app/license" className="nav-link">
              <IconLicense style={{ paddingBottom: "5px" }} />
              License
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/app/profile" className="nav-link">
              <IconUser style={{ paddingBottom: "5px" }} />
              Profile
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/app"
              className="nav-link active"
              onClick={() => dispatch(logout(navigate))}
            >
              <IconLogout style={{ paddingBottom: "4px", paddingTop: "2px" }} />
              Logout
            </Link>
          </li>
        </ul>
      </header>
    </div>
  );
}
