import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getToken } from "../slices/authSlice";

export default function NavBar() {
  let token = useSelector(getToken);

  return (
    <div className="container">
      <header className="d-flex flex-wrap justify-content-center py-3 mb-4 px-5 mx-5">
        <Link
          to="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <img
            alt="mljar logo"
            src={process.env.PUBLIC_URL + "/logo_blue.png"}
            style={{
              height: "50px",
              padding: "0px",
              margin: "0px 10px 0px 0px",
            }}
          />
          <span className="fs-4">Licenses</span>
        </Link>

        <ul className="nav nav-pills">
          {/* <li className="nav-item">
            <Link to="https://runmercury.com/pricing/" className="nav-link">
              Pricing
            </Link>
          </li>
          <li className="nav-item">
            <Link to="https://mljar.com/docs/" className="nav-link">
              About
            </Link>
          </li>

          <li className="nav-item">
            <Link to="https://mljar.com/contact/" className="nav-link">
              Contact
            </Link>
          </li> */}
          {/* {!token && (
            <li className="nav-item">
              <b className="nav-link px-1" style={{ color: "gray" }}>
                |
              </b>
            </li>
          )} */}
          {!token && (
            <li className="nav-item">
              <Link to="/register" className="nav-link">
                Sign Up
              </Link>
            </li>
          )}
          {!token && (
            <li className="nav-item">
              <Link to="/login" className="nav-link active">
                Login
              </Link>
            </li>
          )}
          {token && (
            <li className="nav-item">
              <Link to="/app" className="nav-link active">
                Open Dashboard
              </Link>
            </li>
          )}
        </ul>
      </header>
    </div>
  );
}
