import Title from "../components/TitleChanger";

export default function ContactView() {
  return (
    <div>
    <Title pageTitle={ 'Contact | ' } />
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2>Contact</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
