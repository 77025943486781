import { useEffect } from "react";

type Props = {
    pageTitle: string;
  };

function Title({ pageTitle }: Props) {
  useEffect(() => {
    if (typeof pageTitle === "string") {
      document.title = pageTitle + 'MLJAR Studio Licenses';
    } else {
      console.error("Invalid pageTitle: pageTitle must be a string");
    }
  }, [pageTitle]);

  return null;
}

export default Title;
