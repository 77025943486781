import { IconExternalLink } from "@tabler/icons-react";

export default function Footer() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <footer className="py-3 my-4">
            <hr />
            {/* <ul className="nav justify-content-center border-bottom pb-3 mb-3">
              <li className="nav-item">
                <a
                  href="https://mljar.com/pricing/"
                  className="nav-link px-2 text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  Pricing
                  <IconExternalLink style={{ paddingBottom: "5px" }} />
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://mljar.com/contact/"
                  className="nav-link px-2 text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact
                  <IconExternalLink style={{ paddingBottom: "5px" }} />
                </a>
              </li>
              <li className="nav-item">
                <a href="/register" className="nav-link px-2 text-muted">
                  Sign Up
                </a>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link px-2 text-muted">
                  Login
                </a>
              </li>
            </ul> */}
            <ul className="nav justify-content-center pb-2">
              <li className="nav-item">
                <a
                  href="https://mljar.com/pricing/"
                  className="nav-link px-2 text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  Pricing
                  <IconExternalLink style={{ paddingBottom: "5px" }} />
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://mljar.com/contact/"
                  className="nav-link px-2 text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact
                  <IconExternalLink style={{ paddingBottom: "5px" }} />
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://mljar.com/privacy/"
                  target="_blank"
                  className="nav-link px-2 text-muted"
                  rel="noreferrer"
                >
                  Privacy Policy
                  <IconExternalLink style={{ paddingBottom: "5px" }} />
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://mljar.com/terms/"
                  target="_blank"
                  className="nav-link px-2 text-muted"
                  rel="noreferrer"
                >
                  Terms of Service
                  <IconExternalLink style={{ paddingBottom: "5px" }} />
                </a>
              </li>
            </ul>
            <p className="text-center text-muted">
            <a
                  href="https://mljar.com/"
                  target="_blank"
                  className="nav-link px-2 text-muted"
                  rel="noreferrer"
                >
              © MLJAR Sp. z o.o.<IconExternalLink style={{ paddingBottom: "5px" }} />
              </a>
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}
