import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAccount, UserType } from "../slices/authSlice";
import { useAppDispatch } from "../store";
import { IconTrash } from "@tabler/icons-react";

type Props = {
  user: UserType;
};

export default function DeleteAccount({ user }: Props) {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");

  return (
    <div>
      <h4>
        <IconTrash style={{ paddingBottom: "5px" }} />
        Delete Account
      </h4>
      <p>
        Please write your email <b>{user.email}</b> in the text input below to
        confirm account removal.
      </p>
      <b>
        All your data will be removed from our system and you will be logged
        out.
      </b>
      <div className="my-2">
        <input
          type="text"
          className="form-control"
          placeholder="Write your email to confirm removal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <button
        className="btn btn-danger"
        onClick={(e) => {
          dispatch(deleteAccount(navigate));
        }}
        disabled={email !== user.email}
      >
        Permanently Delete
      </button>
    </div>
  );
}
