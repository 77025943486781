export default function PricingView() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2>Pricing</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
