import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Title from "../components/TitleChanger";
import { isAuthenticated } from "../slices/authSlice";

export default function HomeView() {
  const navigate = useNavigate();
  const isLogged = useSelector(isAuthenticated);
  return (
    <div>
      <Title pageTitle={""} />
      <div className="container">
        <div className="col-md-10 offset-md-1">
          <div className="row py-4">
            <div className="col my-auto">
              <h1 className="display-4" style={{ fontWeight: "800" }}>
                Programming suite for everyone
              </h1>
              <p
                className="lead"
                style={{ fontWeight: "300", color: "rgb(30 41 59)" }}
              >
                MLJAR Studio is a new way for programming. You can download
                installer and buy license in this portal.
              </p>
              <>
                {!isLogged && (
                  <>
                    <button
                      className="btn btn-primary px-4 py-2"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </button>
                    <button
                      className="btn btn-outline-dark mx-2 px-4 py-2"
                      onClick={() => navigate("/register")}
                    >
                      Sign Up
                    </button>
                  </>
                )}
                {isLogged && (
                  <button
                    className="btn btn-primary px-4 py-2"
                    onClick={() => navigate("/app")}
                  >
                    Open Dashboard
                  </button>
                )}
              </>
            </div>
            <div className="col my-auto d-none d-md-block">
              <img
                alt="MLJAR Studio forever"
                src="/icon.svg"
                style={{ width: "80%" }}
              />
            </div>
          </div>
          <div className="row pt-3">
            <h2 className="" style={{ fontWeight: "700" }}>
              In this portal you can
            </h2>{" "}
          </div>
          <div className="row pt-3">
            <div className="col-4">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">Download installer</h5>
                  <p className="card-text">
                    You can download installer for your operating system.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">Manage license</h5>
                  <p className="card-text">
                    You can buy and manage license for your MLJAR Studio.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">Check updates</h5>
                  <p className="card-text">
                    Always use the latest version with new features.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <p className="pt-4">
              <div className="alert alert-primary" role="alert">
                MLJAR Studio may be downloaded and evaluated for free, however a
                license must be purchased for continued use. There is currently
                no enforced time limit for the evaluation.
                
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
